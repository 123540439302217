// @flow
import React, {Component} from "react"
import styled from "styled-components"
import _ from 'lodash'
import {Tag, Tooltip} from 'antd';
import {Button} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import ComponentTypeIcon from "../ComponentTypeIcon";
import type {componentType_componentType} from "../../graphql/operation-result-types.flow";
import Colors from "../../themes/default/colors";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import {Grow} from "../Flex";
import "./ComponentBrowserListItem.css";
import ComponentAlertBadges from "../ComponentAlertBadges";

type Props = {
    style?: any,
    item: {
        id: string,
        name: string,
        componentType?: componentType_componentType,
        componentList?: {
            total: number
        },
        cuContaminated: boolean,
    },
    onPathIconClick?: Function,
    onAccessoryClick?: Function,
    onClick?: Function,
    highlightComponentId: boolean
}

const Wrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid ${Colors.greyLight};
  
  :hover {
     background-color: ${Colors.greyLighter};
  }
`;

class ComponentBrowserListItem extends Component<Props> {


    _handleOnClick = (e) => {
        if (!_.get(e, "target.classList", []).contains("sub-button")) {
            const {onClick} = this.props;
            if (onClick) {
                onClick();
            }
        }

    };

    _handleSitemapClick = (e) => {
        const {onPathIconClick} = this.props;
        if (onPathIconClick) {
            onPathIconClick();
        }
    };

    _handleAccessoryClick = (e) => {
        e.stopPropagation();
        const {onAccessoryClick} = this.props;
        if (onAccessoryClick) {
            onAccessoryClick();
        }
    };

    render() {

        //@TODO In Service Tag
        const {item, t, highlightComponentId} = this.props;

        const nameLengthTreshold = 35
        const showNameTooltip = item?.name?.length > nameLengthTreshold
        const shortName = showNameTooltip ? `${item.name.substring(0, nameLengthTreshold - 4)}...` : item.name;

        return (
            <Wrapper
                onClick={this._handleOnClick}
                data-cy={'TEST_componentBrowserListItem'}
                style={{backgroundColor: highlightComponentId ? Colors.greyLighter : null}}
            >
                <div className="flex-row cursor-pointer pl-5 pv-5">
                    <Grow>
                        <Button
                            data-cy={'TEST_componentBrowserListItemChildrenButton'}
                            className={"sub-button"}
                            size={"small"}
                            onClick={this._handleSitemapClick}
                        >
                            <FontAwesomeIcon style={{fontSize: "8px"}} name={"sitemap"}/> {_.get(item, 'childrenCount', 0)}
                        </Button>
                    </Grow>

                    <ComponentAlertBadges activeAlerts={_.get(item, 'activeAlerts')}/>

                    {item.cuContaminated === true ? <Tag color={"orange"}>{t("CU")}</Tag> : null}
                    {_.get(item, 'serviceTicketList.total', 0) > 0 ? <Tag color={"blue"}>{t("Service")}</Tag> : null}
                </div>
                <div className="flex-row cursor-pointer">
                    <div className="ph-5 pv-5">
                        <ComponentTypeIcon componentTypeIndex={item.componentType.index} size={16}/>
                    </div>
                    <Tooltip title={showNameTooltip ? item.name : ''}>
                        <div className="component-browser-label flex-grow-1 ph-5 pv-5 cursor-pointer">
                            <span style={{fontSite: 18}}>{shortName}</span>
                        </div>
                    </Tooltip>
                    {
                        _.get(item, 'access.read', true)
                            ? <Button data-cy={'TEST_componentBrowserListItemAccessoryButton'} className={"sub-button mr-5"}
                                      onClick={this._handleAccessoryClick} shape={'circle'}
                                      size={'small'}><FontAwesomeIcon style={{fontSize: 12, marginBottom: 5, marginLeft: 3}}
                                                                      name={"chevron-right"}/></Button>
                            : null
                    }
                </div>
            </Wrapper>
        )
    }

}

export default withTranslation()(ComponentBrowserListItem);
