// @flow
import React, {Component} from 'react';
import ComponentBrowser from "../../components/ComponentBrowser";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import ProfileQuery from "./../../graphql/queries/profile.graphql";
import {withRouter} from "react-router-dom";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {connect} from "react-redux";
import {
    setComponentBrowserClientList,
    setComponentBrowserComponent, setInfrastructureSearchQuery,
    setStockSearchEnabled
} from "../../actions/settings";
import InfrastructureMasterSider from "../../components/InfrastructureMasterSider";
import InfrastructureBookmarks from "../../components/InfrastructureBookmarks";
import ComponentPathQuery from "../../graphql/queries/ComponentPath.graphql";
import ProfileComponentQuery from "../../graphql/queries/profileComponent.graphql";
import ComponentListTotalQuery from "../../graphql/queries/componentListTotal.graphql"
import InfrastructureSearchFilter from "../../components/InfrastructureSearchFilter";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import parseSearchQueryParams from "../../utils/searchParams";
import queryString from "query-string";
import {MAX_SIZE, MIN_SIZE} from "./index";

type Props = {
    finderId: string,
    masterContent: string,
    siderHighlight: string,
    onStockSearch: Function,
    stockSearchEnabled?: boolean,
    onChangeContent: (content: string) => void,
    openComponentId: string,
    isOpen: boolean,
    onClose: () => void,
    onOpen: () => void,
    params?: any,
    onChange?: Function,
};

type State = {
    isMasterOpen: boolean,
}

const ComponentNavigation = ({finderId, masterContent, history, match, stockSearchEnabled, setFinderId, setStockSearch, clientListView, setComponentBrowserClientList, params, onChange}) => {

    return <ComponentBrowser
        finderId={finderId}
        highlightComponentId={_.get(match,'params.index')}
        stockSearchEnabled={stockSearchEnabled}
        onStockSearch={(item) => {
            setStockSearch(!stockSearchEnabled);
        }}
        hideBackButton={false}
        clientListView={clientListView}
        onClick={(item) => {
            if (item.componentType.product) {
                let url = `/infrastructure/${item.id}`;
                history.push(url)
            } else if ((item.componentType.index === "client" || item.componentType.index === "globalTrashPool") && clientListView) {
                setComponentBrowserClientList(false);
                setFinderId(item.id);
            } else {
                setFinderId(item.id);
            }
        }}
        onSetClientListViewActive={() => {
            setFinderId(null);
            setComponentBrowserClientList(true);
        }}
        onSetClientListViewInactive={() => {
            setComponentBrowserClientList(false);
        }}
        onPathIconClick={(item) => {
            setFinderId(item.id);
        }}
        onPathItemClick={(item) => {
            setFinderId(item.id);
        }}
        onAccessoryClick={(item) => {
            history.push(`/infrastructure/${item.id}`)
        }}
    />
};


const ComponentNavigationWrapper = compose(
    withRouter,
    connect((state) => {
        return {
            finderId: _.get(state,'settings.componentBrowser.componentId'),
            stockSearchEnabled: _.get(state,'settings.componentBrowser.stockSearchEnabled'),
            clientListView: _.get(state,'settings.componentBrowser.clientListView'),
        }
    }, (dispatch) => {
        return {
            setStockSearch: (enabled) => {
                return dispatch(setStockSearchEnabled(enabled))
            },
            setFinderId: (finderId) => {
                return dispatch(setComponentBrowserComponent(finderId))
            },
            setComponentBrowserClientList: (enabled) => {
                return dispatch(setComponentBrowserClientList(enabled))
            }
        }
    }),
    graphql(ProfileQuery, {
        name: 'profileQuery',

        options: (props) => ({
            skip: !!props.finderId
        })
    }),
    waitWhileLoading('profileQuery', null, {
        optional: true
    }),
    withProps((props) => {
        if (props.finderId === "initialSetting") {
            return {
                finderId: _.get(props, 'profileQuery.profile.rootComponent.id')
            }
        }
    })
)(ComponentNavigation);

class InfrastructureMaster extends Component<Props, State> {

    _onBookmarkClick = (bookmark: any) => {
        const bookmarkData = JSON.parse(bookmark.data)
        if (bookmark.type === "infrastructureDetail" && bookmarkData.link.includes("/dashboard")) {
            this.props.onChangeContent("componentBrowser", "ownInfrastructure")
        }
        if (bookmark.type === "infrastructureSearch") {
            this.props.onChangeContent("search", "search")
        }
    }

    render() {

        const {
            masterContent,
            siderHighlight,
            onChangeContent,
            t,
            finderId,
            stockSearchEnabled,
            onStockSearch,
            componentPathQuery,
            profileComponentQuery,
            setSearchQuery,
            defaultValues,
            filter,
            clientComponentsTotalQuery
        } = this.props;

        let content = null;

        const path = _.get(componentPathQuery, 'componentInfo.path')
        const rootComponent = _.get(profileComponentQuery, 'profile.rootComponent')
        const showClientList = _.get(clientComponentsTotalQuery, 'componentList.total') > 1

        let siderHighlightNew = siderHighlight;

        if (masterContent === "componentBrowser") {
            // check if you are inside your own infrastructure
            siderHighlightNew = (path?.length > 0 && path.some(x => x.id === rootComponent?.id)) ? "ownInfrastructure" : "clientList"

            content = <ComponentNavigationWrapper
                stockSearchEnabled={stockSearchEnabled}
                onStockSearch={onStockSearch}
                finderId={finderId}
                onClose={this.props.onClose}
            />
        } else if (masterContent === "bookmarks") {
            siderHighlightNew = "bookmarks"

            content = <InfrastructureBookmarks
                onBookmarkClick={this._onBookmarkClick}
            />
        } else if (masterContent === "search") {
            siderHighlightNew = "search"

            content = <InfrastructureSearchFilter
                params={filter}
                onChange={(changes) => {
                    if (!changes) {
                        const query = queryString.stringify(defaultValues);

                        setSearchQuery(query);
                        this.props.history.push({
                            search: query
                        })
                    } else {

                        const params = {...filter, ...changes};
                        let values = Object.keys(params).reduce((acc, cur) => {
                            if (Array.isArray(params[cur]) && !params[cur].length) {
                                acc[cur] = null;
                            } else {
                                acc[cur] = params[cur];
                            }
                            return acc;
                        }, {})

                        const query = queryString.stringify(values, {skipNull: false});
                        setSearchQuery(query)

                        this.props.history.push({
                            search: `?${query}`
                        })
                    }
                }}
            />
        }

        if (this.props.isOpen) {
            return <div className={"flex-row full-size"} style={{width: MAX_SIZE}}>
                <InfrastructureMasterSider
                    rootComponent={rootComponent}
                    isOpen={this.props.isOpen}
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                    onChangeContent={(content, siderHighlight) => onChangeContent(content, siderHighlight)}
                    siderHighlight={siderHighlightNew}
                    showClientList={showClientList}
                />
                <div
                    className={"infrastructure-master-content full-size white-bg"}
                    style={{padding: masterContent === "search" ? "10px" : "", width: MAX_SIZE - MIN_SIZE}}
                >
                    {content}
                </div>
            </div>
        }

        return <div className={"full-size"} style={{width: MIN_SIZE}}>
            <InfrastructureMasterSider
                rootComponent={rootComponent}
                isOpen={this.props.isOpen}
                onOpen={this.props.onOpen}
                onClose={this.props.onClose}
                onChangeContent={(content) => {
                    this._onOpenMaster()
                    onChangeContent(content)
                }}
                siderHighlight={siderHighlightNew}
                showClientList={showClientList}
            />
        </div>

    }
}

export default compose(
    withRouter,
    withTranslation(),
    connect((state) => {
        return {
            finderId: _.get(state,'settings.componentBrowser.componentId'),
            stockSearchEnabled: _.get(state,'settings.componentBrowser.stockSearchEnabled'),
            clientListView: _.get(state, 'settings.componentBrowser.clientListView') === "true",
        }
    }, (dispatch) => {
        return {
            setStockSearch: (enabled) => {
                return dispatch(setStockSearchEnabled(enabled))
            },
            setFinderId: (finderId) => {
                return dispatch(setComponentBrowserComponent(finderId))
            },
            setComponentBrowserClientList: (enabled) => {
                return dispatch(setComponentBrowserClientList(enabled))
            }
        }
    }),
    graphql(ProfileComponentQuery, {
        name: 'profileComponentQuery'
    }),
    waitWhileLoading('profileComponentQuery', 'profile', {showLoader: false}),
    withProps((props) => {
        if (props.finderId === "initialSetting") {
            return {
                finderId: _.get(props, 'profileComponentQuery.profile.rootComponent.id')
            }
        }
    }),
    graphql(ComponentPathQuery, {
        name: 'componentPathQuery',
        options: (props: Props) => ({
            variables: {
                id: props.finderId,
            }
        })
    }),
    graphql(ComponentListTotalQuery, {
        name: 'clientComponentsTotalQuery',
        options: (props: Props) => ({
            variables: {
                params: {
                    filter: {
                        componentTypeIndex: {
                            value: ["client"]
                        }
                    }
                }
            }
        })
    }),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'typeDefinitionAttributeListQuery',
        options: (props) => {
            return {
                variables: {
                    index: 'componentFilter'
                }
            }
        }
    }),
    withProps((props) => {
        let query = _.get(props, 'history.location.search');
        return {
            query
        }
    }),
    connect((state, props) => {

        let query = props.query;
        if (!query || !query.length) {
            query = _.get(state, 'settings.infrastructureSearchQuery');
        }

        return {
            query,
        }
    }, dispatch => ({
        setSearchQuery: (query) => {
            return dispatch(setInfrastructureSearchQuery(query))
        }
    })),
    withProps((props) => {

        let defaultValues = {
            ..._.get(props, 'typeDefinitionAttributeListQuery.typeDefinitionAttributeList.typeDefinitionAttributes', []).reduce((acc, cur) => {
                if (cur.value) {
                    acc[_.get(cur, 'index')] = _.get(cur, 'value');
                }
                if (cur.addOnAttribute) {
                    acc[_.get(cur, 'addOnAttribute.index')] = _.get(cur, 'addOnAttribute.value');
                }
                return acc;
            }, {}),
        }

        return {
            defaultValues,
            queryParams: parseSearchQueryParams(props.query)
        }
    }),
    withProps((props) => {
        const filter = props.query && props.query.length ? props.queryParams : props.defaultValues;
        return {
            filter
        }
    }),
)(InfrastructureMaster)
